@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lexend&display=swap");
body {
  margin: 0;
    font-family: 'Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: 'Lexend', monospace;
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.MuiPaginationItem-root.Mui-selected{
  border: 2px solid rgb(112 166 179 / var(--tw-bg-opacity)) !important;
  color:  rgb(112 166 179 / var(--tw-bg-opacity)) !important;
  background: #fff !important;
}

.MuiButtonBase-root-MuiPaginationItem-root{
  background:  rgb(112 166 179 / var(--tw-bg-opacity)) !important;
  color: #fff !important;
}
.MuiPaginationItem-previousNext{
  background:  rgb(112 166 179 / var(--tw-bg-opacity)) !important;
  color: #fff !important;
}